<template>
    <div class="pb-[30px]">
        <div class="flex flex-row  justify-between items-center">
          <div v-if="viewType === 'table'" class="pr-5 text-xs flex  flex-wrap gap-4 pb-3">
              <v-text-field placeholder="Customer Name" variant="outlined" class="w-32" density="compact" @keyup.enter="searchFilter" v-model="name" rounded="0">
              </v-text-field>
              <v-text-field placeholder="Customer Email" variant="outlined" class="w-32" density="compact" @keyup.enter="searchFilter" v-model="email" rounded="0">
              </v-text-field>
              <v-text-field placeholder="Type" variant="outlined" class="w-32" density="compact" @keyup.enter="searchFilter" v-model="type" rounded="0">
              </v-text-field>
              <v-text-field placeholder="Status" variant="outlined" density="compact" class="w-32" @keyup.enter="searchFilter" v-model="status" rounded="0">
              </v-text-field>
              <v-btn icon="mdi-magnify" size="small" style="outline: none;" @click="searchFilter" class="bg-dark text-light"></v-btn>
          </div>
          <div v-else-if="viewType === 'graph'" class="pr-5 text-xs flex-wrap flex gap-x-4 pb-3 items-center">
            
            <div class="float-left  px-2 py-2 h-20 min-w-36">
              Server Name
              <v-select v-model="selectedServerType" :items="serverNames" density="compact" item-title="name" rounded="0"
                variant="outlined" placeholder="Select Server Name" style="padding-top: 0px;padding-bottom: 0px;">
                <template v-slot:selection="{ item }"  >
                  <v-list-item :title="item.raw.name" density style="padding: 0;">
                    <template v-slot:prepend>
                      <v-icon  v-if="item.raw.name!=='All'" :color="item.raw.conn_status ? 'green' : 'red'" size="10" density="compact">mdi-circle</v-icon>
                    </template>
                  </v-list-item>
                </template>
                <template v-slot:item="{ props, item }">
                  <v-list-item class="w-[250px]" v-bind="props" :title="item.raw.name"   density="compact">
                    <template v-slot:prepend>
                      <v-icon  v-if="item.raw.name!=='All'" :color="item.raw.conn_status ? 'green' : 'red'" size="10">mdi-circle</v-icon>
                    </template>
                  </v-list-item>
                </template>
              </v-select>
            </div>
            <div class="float-left  px-2 py-2 h-20 w-36">
              From:
              <VueDatePicker  v-model="fromDate" format='dd/MM/yyyy' class="text-black bg-white"></VueDatePicker >
            </div>
            <div class="float-left px-2 py-2 h-20 w-36">
                To:
                <VueDatePicker  v-model="toDate" format='dd/MM/yyyy' class="text-black bg-white"></VueDatePicker >
            </div>
            <v-btn icon="mdi-magnify" size="small" style="outline: none;" @click="searchGraphFilter" class="bg-dark text-light"></v-btn>
          </div>
        </div>
        <div class="flex items-center flex-wrap justify-between">
          <div class="flex flex-wrap items-center">
            <div class="float-left  pr-2 py-2 h-20 w-36">
              From:
              <VueDatePicker v-model="fromExportDate" format='dd/MM/yyyy' class="text-black bg-white"></VueDatePicker >
            </div>
            <div class="float-left px-2 py-2 h-20 w-36 mr-3">
                To:
                <VueDatePicker v-model="toExportDate" format='dd/MM/yyyy' class="text-black bg-white"></VueDatePicker >
            </div>
            <div class="float-left px-2 mt-[20px] mr-3">
              <input :checked="isAll" v-model="isAll" class="form-check-input shadow-none mr-1" type="checkbox" />
              All
            </div>
            <div class="mt-[20px]">
              <v-btn size="small" style="outline: none;" @click="exportCSV" class="bg-dark text-light">Export</v-btn>
            </div>
          </div>
          <div class="my-3">
            Total Transaction Count: {{ totalCount }}
          </div>
        </div>
        <div class="flex flex-row items-end justify-between">
          <v-btn-toggle v-model="viewType" color="deep-purple-accent-3 " rounded="0" group class="border-b-none border"
            mandatory>
            <v-btn value="table" class="bg-white text-black">
                Table
            </v-btn>
            <v-btn value="graph" class="bg-white text-black ">
                Graph
            </v-btn>
          </v-btn-toggle>
          <div v-if="viewType === 'graph'" class="float-left  pr-2 min-w-30">
            Type
            <v-select v-model="selectedType" :items="typeNames" density="compact" item-title="name" rounded="0"
              variant="outlined" placeholder="Select Graph Type" style="padding-top: 0px;padding-bottom: 0px;">
              <template v-slot:selection="{ item }"  >
                <v-list-item :title="item.raw.name" density style="padding: 0;">
                  <template v-slot:prepend>
                    {{item.value}}
                  </template>
                </v-list-item>
              </template>
              <template v-slot:item="{ props, item }">
                <v-list-item v-bind="props" :title="item.raw.name"   density="compact">
                  <template v-slot:prepend>
                    {{ item.value }}
                  </template>
                </v-list-item>
              </template>
            </v-select>
          </div>
        </div>
        
        <TableView v-if="viewType === 'table'" :filterName="name" :filterEmail="email" :filterStatus="status" :filterType="type" />
        <GraphView modem v-if="viewType === 'graph'" class="border bg-white text-black" :graphType="selectedType" :serverName="selectedServerType" :from="fromDate" :to="toDate" />
    </div>
</template>
<script setup>
import { ref, onMounted, defineProps } from 'vue';
import GraphView from "./GraphView.vue";
import TableView from "./TableView.vue"
import { useAdminTransactionStore, useAdminProxyStore, useAccountStore } from "@/stores"
import { storeToRefs } from 'pinia';
import VueDatePicker from '@vuepic/vue-datepicker';

const props = defineProps({
    selectedEmail: {
        type: String, // Assuming user object has a name property
    },
});

const name = ref("");
const email = ref(props.selectedEmail ? props.selectedEmail : '');
const type = ref("");
const status = ref("");
const selectedServerType = ref("All");
const viewType = ref("table");
const typeNames = ref(['Daily', 'Weekly', 'Monthly']);
const selectedType = ref('Daily');

const nowDate = new Date();
const oneMonthAgoDate = new Date(nowDate);
oneMonthAgoDate.setMonth(nowDate.getMonth() - 1);

const fromDate = ref(oneMonthAgoDate);
const toDate = ref(nowDate);
const fromExportDate = ref(oneMonthAgoDate);
const toExportDate = ref(nowDate);
const isAll = ref(false)
let filterName = '';
let filterEmail = props.selectedEmail ? props.selectedEmail : '';
let filterType = '';
let filterStatus = '';
const adminTransactionStore = useAdminTransactionStore()
const adminProxyStore = useAdminProxyStore()
const { totalCount } = storeToRefs(adminTransactionStore)
const {serverNames} = storeToRefs(adminProxyStore)
onMounted(() => {
  adminTransactionStore.loadCount();
  adminProxyStore.loadServerNames()
});

const searchFilter = () => {
  filterEmail = email.value;
  filterName = name.value;
  filterStatus = status.value;
  filterType = type.value;
  adminTransactionStore.loadTransactions({name: filterName, email: filterEmail, type: filterType, status: filterStatus, from: 0, count: 50});
}

const searchGraphFilter = () => {
  const from = fromDate.value.toISOString().split('T')[0]
  const to = toDate.value.toISOString().split('T')[0]
  adminTransactionStore.loadTransactions({fromDate: from, toDate: to, serverName: selectedServerType.value});
}
const { user } = useAccountStore();
const baseUrl = `${process.env.VUE_APP_VUE_API_URL}`;
const exportCSV = () => {
  const from = fromExportDate.value.toISOString().split('T')[0]
  const to = toExportDate.value.toISOString().split('T')[0]
  const backendUrl = `${baseUrl}/admin/export-csv`;
  fetch(backendUrl, {
        method: 'POST', // or POST, depending on your API
        headers: {
            'Content-Type': 'application/json', // Specify the content type
            'x-access-token':  `${user.accessToken}`
        },
        body: JSON.stringify({
          isAll: isAll.value, from, to
        })
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok.');
        }
        return response.blob();
    })
    .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `transactions-${formatDate(new Date())}.csv`; // Default file name
        document.body.appendChild(a);
        a.click(); // Trigger the download
        window.URL.revokeObjectURL(url); // Clean up
    })
    .catch(error => console.error('Error downloading the file:', error));
}

const formatDate = (date_m) => {
  const date = new Date(date_m);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}${month}${day}${hours}${minutes}${seconds}`;
}

</script>