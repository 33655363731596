<template>
  <div class="">
      <div class="flex justify-between items-center my-2 flex-wrap">
          <div class="w-1/5 pr-5 text-xs py-2 min-w-[200px]">
              Search (Email, User Name, Full Name, Address, City, Country)
              <v-text-field placeholder="Name" variant="outlined" density="compact" v-model="searchName" rounded="0">
              </v-text-field>
          </div>
          <div class="flex flex-row items-center gap-x-3">
            Referral Commission:
            <v-text-field variant="outlined" class="w-[60px]" density="compact" v-model="commission" rounded="0">
            </v-text-field>%
            <v-btn size="small" style="outline: none;" @click="saveCommission" class="bg-dark text-light">Save</v-btn>
          </div>
      </div>
      <v-data-table :headers="headers" :items="Users.filter(u => [u.email, u.user_name, u.name, u.address, u.city, u.country].join(',').toLowerCase().includes(searchName.toLowerCase()))" 
          item-key="name" class="border h-[500px] bg-white text-black min-h-[500px]" 
          items-per-page="-1"
          style="font-size: 13px;" :style="`height: ${computedHeight}px;`">
          <template v-slot:[`item.no`]="{ index }">
              {{ index + 1 }}
          </template>
          <template v-slot:[`item.checkbox`]="{ item }">
              <div @click="toggleSelection(item)" class="ml-auto align-self-center"
                  style="width: 20px; height: 20px; cursor: pointer;">
                  <input :checked="isSelected(item)" class="form-check-input shadow-none" type="checkbox" />

              </div>
          </template>
          <template v-slot:[`item.transactions`]="{ item }">
              <router-link
                  class="text-white"
                  :to="{ name: 'AdminTransactionPage', query: { email: item.email }}"
              >
                  View
              </router-link>
          </template>
          <template v-slot:[`item.buttonBar`]="{ item }">
              <v-menu offset-y location="bottom">
                  <template v-slot:activator="{ props, on, isActive }">
                      <v-btn v-bind="props" v-on="{ ...on }" color="#234" density="compact"
                          :style="isActive ? 'border-bottom-left-radius: 0px;border-bottom-right-radius: 0px;' : ''"
                          style="outline: none; font-size: 10px; " class="w-32 z-10 bg-dark text-light">
                          Actions
                          <v-icon icon="mdi-chevron-down" size="14"></v-icon>
                      </v-btn>
                  </template>
                  <v-list class="w-32 flex-1 flex flex-col px-0 py-0  z-0"
                      style="border-top-left-radius: 0px;border-top-right-radius: 0px">
                      <v-btn v-for="(button, index) in actionButtons" :key="index" @click="clickAction(item, button)"
                          rounded="0" class="text-center bg-dark text-light"
                          style="font-size: 10px; width: 128px; outline: none; border-bottom: 1px solid #345;"
                          :data-bs-toggle="button === 'Update' && 'modal'"
                          :data-bs-target="button === 'Update' && '#customerUpdateModal'">
                          {{
                  button }}
                      </v-btn>
                  </v-list>
              </v-menu>
          </template>
      </v-data-table>
  </div>
  <div>
      <AddNewReseller @close="addNewOneDialogFlag = false" @updated="updatedHandler" />
      <UpdateInfoDialog :item="selectedItem" @updated="updatedHandler" />

  </div>
</template>
<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useAdminUserStore } from "@/stores"
import { storeToRefs } from 'pinia';
import AddNewReseller from "./Modals/AddNewReseller.vue"
import UpdateInfoDialog from "./Modals/UpdateInfoDialog.vue"

const headers = ref(
  [
      { title: 'No', align: 'center', key: 'no' },
      { title: '', align: 'center', key: 'checkbox' },
      { title: 'User Name', align: 'center', key: 'user_name' },
      { title: 'Full Name', align: 'center', key: 'name' },
      { title: 'Email', align: 'center', key: 'email' },
      { title: 'Role', align: 'center', key: 'role' },
      { title: 'Balance', align: 'center', key: 'balance' },
      { title: 'Address', align: 'center', key: 'address' },
      { title: 'City', align: 'center', key: 'city' },
      { title: 'Country', align: 'center', key: 'country' },
      { title: 'Transactions', align: 'center', key: 'transactions', width: '200px', minWidth: '200px' },
      { title: 'Actions', align: 'center', key: 'buttonBar' },
  ]
);

const addNewOneDialogFlag = ref(false)
const updateInfoDialogFlag = ref(false)
const searchName = ref("")

const adminUserStore = useAdminUserStore()
const { Users, commission } = storeToRefs(adminUserStore)
adminUserStore.loadReferrerUsers()
const selectedItems = ref([]);
const selectedItem = ref(null);

const offsetHeight = 300; // Adjust this value as needed

const computedHeight = ref(window.innerHeight - offsetHeight);

const handleResize = () => {
  computedHeight.value = window.innerHeight - offsetHeight;
};

onMounted(() => {
window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
window.removeEventListener('resize', handleResize);
});

const isSelected = (item) => {
  return selectedItems.value.includes(item); 
};

const saveCommission = () => {
    adminUserStore.saveCommission(commission.value);
}

const toggleSelection = (item) => {
  const index = selectedItems.value.indexOf(item);
  if (index === -1) {
      selectedItems.value.push(item);
  } else {
      selectedItems.value.splice(index, 1);
  }
};


const actionButtons = ref(
  ["Update"]
)
const clickAction = (item, button) => {
  switch (button) {
      case "Update":
          selectedItem.value = item
          updateInfoDialogFlag.value = true
          break
  }
}

const updatedHandler = () => {
  addNewOneDialogFlag.value = false
  updateInfoDialogFlag.value = false
  adminUserStore.loadResellerUsers()
}
</script>