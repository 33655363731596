<template>
    <v-data-table :headers="headers" :items="filteredProxies" item-key="name" items-per-page="-1"
        class="border h-[500px] bg-white text-black min-h-[500px]" style="font-size: 13px;" :style="`height: ${computedHeight}px;`">

        <template v-slot:[`item.no`]="{ index }">
            {{ index + 1 }}
        </template>
        <template v-slot:[`item.modem`]="{ item }">
            <div class="flex items-center">
                <v-icon icon="mdi-circle" size="15" :color="item.status === 'CONNECTED' ? 'green' : 'red'"
                    class="mr-2"></v-icon>
                {{ item.modem }}
            </div>
        </template>
        <template v-slot:[`item.ports`]="{ item }">
            <v-icon icon="mdi-alpha-h-circle-outline" color="green"></v-icon>
            {{ item.http_port }} /
            <v-icon icon="mdi-alpha-s-circle-outline" color="green"></v-icon>
            {{ item.sock_port }}
        </template>
        <template v-slot:[`item.pos`]="{item}">
            {{ item.proxyType === 'XPROXY' ? item.position : item.portID }}
        </template>
        <template v-slot:[`item.checkbox`]="{ item }">
            <div @click="toggleSelection(item)" class="ml-auto align-self-center"
                style="width: 20px; height: 20px; cursor: pointer;">
                <input :checked="isSelected(item)" class="form-check-input shadow-none" type="checkbox" />
            </div>
        </template>
        <!-- <template v-slot:[`item.ul_bytes`]="{ item }">
            {{ item.ul_bytes }} MB
        </template>
        <template v-slot:[`item.dl_bytes`]="{ item }">
            {{ item.dl_bytes }} MB
        </template>
        <template v-slot:[`item.all_bytes`]="{ item }">
            {{ item.all_bytes }} MB
        </template> -->
        <template v-slot:[`item.bytes`]="{ item }">
            <div v-if="item.modem_type==='X_PROXY'">
                <div :style="checkDownloadLimit(item, item.http_dl_bytes) ? 'color: red' : ''">
                    HTTP: 
                    {{ item.http_dl_bytes }} MB
                </div>
                <div :style="checkDownloadLimit(item, item.sock_dl_bytes) ? 'color: red' : ''">
                    SOCKS5:  
                    {{ item.sock_dl_bytes }} MB
                </div>
            </div>
            <div v-else-if="item.modem_type==='PROXYSMART'">
                <div :style="checkDownloadLimit(item, item.dl_bytes) ? 'color: red' : ''">
                    {{ item.dl_bytes }}
                </div>
            </div>
        </template>
        <template v-slot:[`item.buttonBar`]="{item}">
            <v-btn
                @click="clickAction(item)" rounded="0" class="text-center bg-dark text-light "
                style="font-size: 10px; width: 80px; outline: none; border-bottom: 1px solid #345; height: 30px"
                :data-bs-toggle="'modal'"
                :data-bs-target="item.modem_type === 'X_PROXY'?'#xproxyUpdateModal':'#proxysmartUpdateModal'"
                >Update</v-btn>
        </template>
    </v-data-table>
    <UpdateDialoagXProxy :item="selectedItem" @updated="updatedHandler"/>
    <UpdateDialogProxySmart :item="selectedItem" @updated="updatedHandler"/>
</template>

<script setup>
import { ref, watchEffect, defineProps, defineExpose, onMounted, onBeforeUnmount } from 'vue';
import { useAdminProxyStore, useAlertStore } from "@/stores"
import UpdateDialoagXProxy from './Modals/UpdateDialoag-XProxy.vue';
import UpdateDialogProxySmart from './Modals/UpdateDialog-ProxySmart.vue';
import { storeToRefs } from 'pinia';
const adminProxyStore = useAdminProxyStore()
const { xProxies, smartProxies } = storeToRefs(adminProxyStore)
const filteredProxies = ref([])
const filteredProxies1 = ref([])

const props = defineProps({
    selectedSaleStatus: {
        type: Object, // Assuming user object has a name property
        required: true
    },
    selectedConnStatus: {
        type: Object, // Assuming user object has a name property
        required: true
    },
    selectedServerName: {
        type: Object, // Assuming user object has a name property
        required: true
    },
    selectedServerType: {
        type: Object, // Assuming user object has a name property
        required: true
    },
});


const headers = ref(
    
);

const checkDownloadLimit = (item, value) => {
    if(item.modem_type==='X_PROXY') {
        if(item.downloadLimitType !== 'unlimited' && item.downloadLimitTimeUnit.toUpperCase() === "DAILY" && parseFloat(item.downloadLimitAmount) <= parseFloat(value)) {
            return true
        }
        else return false
    } else {
        if(Number(value) !== 0 && item.quotaDirType === 'Download' && item.quotaTimeUnit.toUpperCase() === "DAILY") {
            const regex = /(\d+(\.\d+)?)\s*(\w+)/;
            const matches = value.match(regex);
            if(matches[3]?.toUpperCase() === 'MB' && parseFloat(matches[1]) >= parseFloat(item.quotaAmount)) return true
            else if(matches[3]?.toUpperCase() === 'GB' && (parseFloat(matches[1]) * 1024) >= parseFloat(item.quotaAmount)) return true
            else return false

        } else return false
    }
}

const selectedSaleStatus = ref('All');
const selectedConnStatus = ref('All');

const offsetHeight = 400; // Adjust this value as needed

const computedHeight = ref(window.innerHeight - offsetHeight);

const handleResize = () => {
  computedHeight.value = window.innerHeight - offsetHeight;
};

onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});

const selectedItems = ref([])
const selectedItem = ref(null)
let updateInfoDialogFlag = ref(false)

const clickAction = (item) => {
    selectedItem.value = item
    updateInfoDialogFlag = true;
}
const updatedHandler = () => {
    updateInfoDialogFlag.value = false
}

watchEffect(() => {
    if (props.selectedServerType === 'X_PROXY') {
        filteredProxies.value = xProxies.value.filter(proxy =>
            (proxy.usage_status.toUpperCase() === selectedSaleStatus.value.toUpperCase() || selectedSaleStatus.value === 'All') &&
            (proxy.status.toUpperCase() === selectedConnStatus.value.toUpperCase() || selectedConnStatus.value === 'All') &&
            (proxy.modem_type === props.selectedServerType)
        );
        headers.value = [
        { title: 'No', align: 'center', key: 'no' },
        { title: '', align: 'center', key: 'checkbox' },
        { title: 'Modem', align: 'center', key: 'modem' },
        { title: 'Position', align: 'center', key: 'position' },
        { title: 'Ports', align: 'center', key: 'ports' },
        // { title: 'UL Counter', align: 'center', key: 'ul_bytes' },
        // { title: 'DL Counter', align: 'center', key: 'dl_bytes' },
        // { title: 'All Counter', align: 'center', key: 'all_bytes' },
        { title: 'Download', align: 'center', key: 'bytes' },
        // { title: 'Counter Updated', align: 'center', key: 'counter_updated' },
        { title: 'Authentication User', align: 'center', key: 'auth_user' },
        { title: 'Autorization IPs', align: 'center', key: 'auth_ips' },
        { title: 'Usage Status', align: 'center', key: 'usage_status' },
        // { title: 'Status', align: 'center', key: 'status' },
        { title: 'Customer', align: 'center', key: 'customer_name' },
        { title: 'Update', align: 'center', key: 'buttonBar' },
    ]
    }
    else if (props.selectedServerType === 'PROXYSMART') {
        filteredProxies.value = smartProxies.value.filter(proxy =>
            (proxy.usage_status.toUpperCase() === selectedSaleStatus.value.toUpperCase() || selectedSaleStatus.value === 'All') &&
            (proxy.status.toUpperCase() === selectedConnStatus.value.toUpperCase() || selectedConnStatus.value === 'All') &&
            (proxy.modem_type === props.selectedServerType)
        );
        
        headers.value = [
        { title: 'No', align: 'center', key: 'no' },
        { title: '', align: 'center', key: 'checkbox' },
        { title: 'Modem', align: 'center', key: 'modem' },
        { title: 'Port ID', align: 'center', key: 'portID' },
        { title: 'Ports', align: 'center', key: 'ports' },
        // { title: 'UL Counter', align: 'center', key: 'ul_bytes' },
        // { title: 'DL Counter', align: 'center', key: 'dl_bytes' },
        // { title: 'All Counter', align: 'center', key: 'all_bytes' },
        { title: 'Download', align: 'center', key: 'bytes' },
        // { title: 'Counter Updated', align: 'center', key: 'counter_updated' },
        { title: 'Authentication User', align: 'center', key: 'auth_user' },
        { title: 'Autorization IPs', align: 'center', key: 'auth_ips' },
        { title: 'Usage Status', align: 'center', key: 'usage_status' },
        // { title: 'Status', align: 'center', key: 'status' },
        { title: 'Customer', align: 'center', key: 'customer_name' },
        { title: 'Update', align: 'center', key: 'buttonBar' },
    ]
    }
    else if (props.selectedServerType === 'ALL') {
        filteredProxies.value = smartProxies.value.filter(proxy =>
            (proxy.usage_status.toUpperCase() === selectedSaleStatus.value.toUpperCase() || selectedSaleStatus.value === 'All') &&
            (proxy.status.toUpperCase() === selectedConnStatus.value.toUpperCase() || selectedConnStatus.value === 'All') &&
            (proxy.modem_type === 'PROXYSMART')
        );
        filteredProxies1.value = xProxies.value.filter(proxy =>
            (proxy.usage_status.toUpperCase() === selectedSaleStatus.value.toUpperCase() || selectedSaleStatus.value === 'All') &&
            (proxy.status.toUpperCase() === selectedConnStatus.value.toUpperCase() || selectedConnStatus.value === 'All') &&
            (proxy.modem_type === 'X_PROXY')
        );
        filteredProxies1.value.forEach(proxy => {
            filteredProxies.value.push({...proxy, proxyType: 'XPROXY'})
        });
        headers.value = [
        { title: 'No', align: 'center', key: 'no' },
        { title: '', align: 'center', key: 'checkbox' },
        { title: 'Modem', align: 'center', key: 'modem' },
        { title: 'Port ID Or Position', align: 'center', key: 'pos' },
        { title: 'Ports', align: 'center', key: 'ports' },
        // { title: 'UL Counter', align: 'center', key: 'ul_bytes' },
        // { title: 'DL Counter', align: 'center', key: 'dl_bytes' },
        // { title: 'All Counter', align: 'center', key: 'all_bytes' },
        { title: 'Download', align: 'center', key: 'bytes' },
        // { title: 'Counter Updated', align: 'center', key: 'counter_updated' },
        { title: 'Authentication User', align: 'center', key: 'auth_user' },
        { title: 'Autorization IPs', align: 'center', key: 'auth_ips' },
        { title: 'Usage Status', align: 'center', key: 'usage_status' },
        // { title: 'Status', align: 'center', key: 'status' },
        { title: 'Customer', align: 'center', key: 'customer_name' },
        { title: 'Update', align: 'center', key: 'buttonBar' },
    ]
    }
}, [xProxies.value, smartProxies.value, selectedSaleStatus.value, selectedConnStatus.value, props.selectedServerType])


const isSelected = (item) => {
    return selectedItems.value.includes(item);
};
const toggleSelection = (item) => {
    const index = selectedItems.value.indexOf(item);
    if (index === -1) {
        selectedItems.value.push(item)
    } else {
        selectedItems.value.splice(index, 1)
    }
};

watchEffect(() => {
    selectedSaleStatus.value = props.selectedSaleStatus
}, [props.selectedSaleStatus])

watchEffect(() => {
    selectedConnStatus.value = props.selectedConnStatus
}, [props.selectedConnStatus])
const deleteProxies = () => {

    // Your logic to delete proxies
    if (selectedItems.value.find(item => item.usage_status.toUpperCase() === 'USED')) {
        const alertStore = useAlertStore()
        alertStore.warning("Can't delete used proxies")
        return
    }
    adminProxyStore.deleteProxy(selectedItems.value.map(item => item.uuid)).then(() => {
        selectedItems.value = []
        adminProxyStore.loadProxies(props.selectedServerName)
    })

}
defineExpose({
    deleteProxies
});
</script>
